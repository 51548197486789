import React, { Component } from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {AccountBalance, CreditCard, Email, PanoramaFishEye, Phone} from "@material-ui/icons";
import ComponentHeader from "./ComponentHeader";
import adres from "assets/img/adres.JPG";
import contact from "assets/img/contact.JPG";
import Hidden from "@material-ui/core/Hidden";

class Contact extends Component {
  render() {
    return (
      <div style={{ textAlign: "center"}}>
        <ComponentHeader title={"Contact"} />

        <h3>Cadeautip!</h3>
        Wij kunnen ook een cadeaubon voorzien voor elk gewenst bedrag. Voor meer informatie contacteer ons!
        <br/>
        <br/>
        <br/>
        <GridContainer justify={"center"} style={{textAlign: "center"}}>
            <Hidden smDown>
              <GridItem md={1}/>
              <GridItem md={3}>
                <img width="350" src={contact} alt={"contact"}/>
              </GridItem>
            </Hidden>
          <GridItem xs={12} md={4}>
            <h3>Info</h3>
            Relax bv <br/>
            <AccountBalance /> &nbsp; BTW: BE 0746505664
            <br/>
            <br/>
            Bouwelse Steenweg 116 <br/>
            2270 Herenthout
            <br/>
            <br/>
            <h3>Contact</h3>
            <Email /> &nbsp; <a style={{color: "#FFFFFF"}} href="mailto:relax@relax-privesauna.be"> relax@relax-privesauna.be </a><br/>
            <Phone /> &nbsp; <a style={{color: "#FFFFFF"}} href="tel:+32498483949"> 0498/48.39.49 </a>
            <br/>
            <br/>
            <CreditCard /> &nbsp; Iban: BE58 132552525579 <br/>
            Betaalopties: Payconiq & Cash
          </GridItem>
          <Hidden smDown>
            <GridItem md={3}>
              <img width="350" src={adres} alt={"contact"}/>
            </GridItem>
            <GridItem md={1}/>
          </Hidden>
        </GridContainer>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default Contact;