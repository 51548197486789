import React, { Component } from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Card from "../components/Card/Card";
import {PanoramaFishEye} from "@material-ui/icons";
import Carousel from "react-slick";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";


import image_1 from "assets/img/carousel/Carousel_1.JPG"
import image_2 from "assets/img/carousel/Carousel_2.JPG"
import image_3 from "assets/img/carousel/Carousel_3.JPG"
import image_4 from "assets/img/carousel/Carousel_4.JPG"
import image_5 from "assets/img/carousel/Carousel_5.JPG"
import image_7 from "assets/img/carousel/Carousel_7.jpg"
import image_8 from "assets/img/carousel/Carousel_8.jpg"
import image_9 from "assets/img/carousel/Carousel_9.JPG"
import image_10 from "assets/img/carousel/Carousel_10.jpg"
import image_11 from "assets/img/carousel/Carousel_11.JPG"
import image_12 from "assets/img/carousel/Carousel_12.jpg"
import image_13 from "assets/img/carousel/Carousel_13.JPG"
import image_14 from "assets/img/carousel/Carousel_14.JPG"
import image_15 from "assets/img/carousel/Carousel_15.JPG"
import image_16 from "assets/img/carousel/Carousel_16.JPG"
import image_17 from "assets/img/carousel/Carousel_17.JPG"
import image_18 from "assets/img/carousel/Carousel_18.JPG"
import image_19 from "assets/img/carousel/Carousel_19.JPG"
import image_20 from "assets/img/carousel/Carousel_20.JPG"
import image_21 from "assets/img/carousel/Carousel_21.JPG"

import ComponentHeader from "./ComponentHeader";

class Facilities extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };

    let images = [
      image_1,
      image_2,
      image_3,
      image_4,
      image_5,
      image_7,
      image_8,
      image_9,
      image_10,
      image_11,
      image_12,
      image_13,
      image_14,
      image_15,
      image_16,
      image_17,
      image_18,
      image_19,
      image_20,
      image_21,
    ]
    return (
      <div style={{ textAlign: "center"}}>
        <ComponentHeader title={"Faciliteiten"} />
        <GridContainer>
          <GridItem xs={12} md={7} >
            <Card carousel>
              <Carousel {...settings}>
                {images.map((image, key)=>{
                  return <div key={key}>
                    <img src={image} alt="Image" className="slick-image" />
                    <div className="slick-caption">
                    </div>
                  </div>
                })}
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={5}>
            <h3>Binnen</h3>
            <GridContainer style={{justify: "left", textAlign: "left"}}>
              <GridItem xs={12} md={6}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                Dubbele regendouche
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12} md={6}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                Relax-hoek
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12} md={6}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                Privé Toilet
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12} md={6}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                Privé Wastafel
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                <u>Infrarood sauna</u>
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                <u>Aromatherapie sauna</u>
                <br/> Sauna met essentiële oliën
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                <u>Traditionele sauna</u>
                <br/>Een gemiddelde temperatuur tussen 75°C en 100°C
                <br/>Een vochtigheidsgraad tussen 20% en 30%
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                <u>Stoomsauna</u>
                <br/>Een gemiddelde temperatuur tussen 45°C en 65°C
                <br/>Een vochtigheidsgraad tussen 40% en 65%
              </GridItem>
            </GridContainer>
              <h3>Buiten</h3>
            <GridContainer style={{justify: "left", textAlign: "left"}}>
              <GridItem xs={12} md={6}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                Wellis Spa (Jacuzzi)
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12} md={6}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                Douche
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12} md={6}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                Privétuin met relax-hoek
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                <u>Zwembad</u>
                <br/> Het hele jaar door verwarmd tot 30°C
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                <u>Barrelsauna</u>
                <br/> Houtgestookt
                <br/> Inclusief overdekte relax zitjes
                <br/>
                <br/>
              </GridItem>
              <GridItem xs={12}>
                <PanoramaFishEye style={{width: '10px'}}/>
                &nbsp;
                <u>Slaap Ton</u>
                <br/> Bed is 1m60 breed
                <br/> Alle beddengoed is inbegrepen
                <br/> Uitzicht over de privétuin
                <br/>
                <br/>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}
export default withStyles(styles)(Facilities);