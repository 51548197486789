import React, {Component} from "react";
import {Hidden} from "@material-ui/core";

class Arrangements extends Component {
  render() {
    const { title } =this.props

    return (
      <div style={{textAlign: "center"}}>
        <Hidden smDown>
          <br/>
          <h1>{title}</h1>
          <br/>
        </Hidden>
        <Hidden mdUp>
          <br/>
          <h1 style={{fontSize: "2.8rem"}}>{title}</h1>
          <br/>
        </Hidden>
      </div>
    );
  }
}

export default Arrangements;