import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product

export var hist = createBrowserHistory();
// export const api_root = "http://192.168.0.121:5000/api"
export const api_root = "https://api.relax-privesauna.be/api"
import './index.css';
import ReactRouter from './views/Components/router';

ReactDOM.render(
  <Router history={hist}>
    <ReactRouter/>
  </Router>
  ,
  document.getElementById('root'));
