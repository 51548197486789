import React, {Component} from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import List from "@material-ui/core/List";
import {ListItem} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import {PanoramaFishEye} from "@material-ui/icons";
import ReactHtmlParser from 'react-html-parser'


class SingleArrangement extends Component {
  render() {
    const {image, arrangement} = this.props

    let image_side = arrangement.image_side
    let items = arrangement.items
    let name = arrangement.name

    return (
      <div style={{textAlign: "center"}}>
        <GridContainer justify={"center"}>
          {image_side && image_side === "left" &&
          <Hidden smDown>
            <GridItem md={3}>
              <img width="350" src={image} alt={name}/>
            </GridItem>
            <GridItem md={1}/>
          </Hidden>
          }
          <GridItem xs={12} md={4}>
            <h3>Relax - {name}</h3>
            <List>
              {items && items.map((item, key) => {
                let my_items = item.split('_')
                let my_item_underlined = ''
                my_items.forEach((my, idx) => {
                  if (idx % 2 === 0) {
                    my_item_underlined = my_item_underlined + my
                  } else {
                    my_item_underlined = my_item_underlined + "<u>" + my + "</u>"
                  }
                })

                return <ListItem key={key}>
                  <PanoramaFishEye style={{width: '10px'}}/>
                  &nbsp;
                  <div>{ReactHtmlParser(my_item_underlined)}</div>
                </ListItem>
              })}
              {arrangement.options && arrangement.options.map((option, key) => {
                if (arrangement.start_time !== null && arrangement.stop_time !== null){
                  return <ListItem key={key}>
                    {arrangement.start_time} tot {arrangement.stop_time}, {option.number_persons} personen: € {option.price}
                  </ListItem>
                }

                return <ListItem key={key}>
                  {option.duration} h, {option.number_persons} personen: € {option.price}
                </ListItem>
              })}
            </List>
          </GridItem>
          {image_side && image_side === "right" &&
            <Hidden smDown>
              <GridItem md={1}/>
              <GridItem md={3}>
                <img width="350" src={image} alt={name}/>
              </GridItem>
            </Hidden>
          }
          <Hidden mdUp>
            <GridItem xs={12}>
              <img width="350" src={image} alt={name}/>
            </GridItem>
          </Hidden>
        </GridContainer>
      </div>
    );
  }
}

export default SingleArrangement;