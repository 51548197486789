import {container} from "assets/jss/material-kit-react.js";
import headerLinksStyle from "../components/headerLinksStyle";
import headerStyle from "../components/headerStyle";

const componentsStyle = () => ({
  container,
  brand: {
    color: "#2f2f2f",
    textAlign: "center",
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0",
  },
  main: {
    background: "#2a2a2a",
    position: "relative",
    zIndex: "3",
  },
  gold2: {
    position: "absolute",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
fontWeight: "bold",
backgroundImage: "linear-gradient(to right, #462523 0, #cb9b51 22%, #f6e27a 45%, #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%)",
color:"transparent",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "transparant"

  },
mainRaised: {
    margin: "-60px 30px 0px",
  '@media (max-width: 739px)': {
    margin: "-60px 5px 0px",
  },
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  textCenter: {
    textAlign: "center",
  },
  navbar: {
    marginBottom: "-20px",
    zIndex: "100",
    position: "relative",
    overflow: "hidden",
    "& header": {
      borderRadius: "0",
    },
  },
  navigation: {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    marginTop: "0",
    // minHeight: "850px",
  },
  ...headerLinksStyle,
  ...headerStyle,
});

export default componentsStyle;
