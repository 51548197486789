import React, {Component} from "react";
import Table from "../components/Table/Table";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import ComponentHeader from "./ComponentHeader";
import ReactHtmlParser from "react-html-parser";
import menu from "data/menu.json"


class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_data: menu,
      menu_loading: false
    }
  }

  render() {
    const {menu_data, menu_loading} = this.state
    if (menu_loading) return (
      <div style={{textAlign: "center"}}>
        <ComponentHeader title={"Menu"} />
        Loading the menu
        <br/>
        <br/>
        <br/>
      </div>
    )

    if (menu_data === undefined) return (
      <div style={{textAlign: "center"}}>
        <ComponentHeader title={"Menu"} />
        Something went wrong displaying the menu
        <br/>
        <br/>
        <br/>
      </div>
    )

    return (
      <div style={{textAlign: "center"}}>
        <ComponentHeader title={"Menu"} />
        <GridContainer justify={"center"}>
              <GridItem xs={12}>
                  <h3>Dranken</h3>
              </GridItem>
              <GridItem xs={12} md={4}>
                  <Table
                    tableData={[
                      ...menu_data["drinks"]["column_left"].map((item)=>{
                        return [item[0], "€" + item[1]]
                      }),
                      ["", ""]
                    ]}
                  />
              </GridItem>
              <GridItem xs={12} md={4}>
                  <Table
                    tableData={[
                      ...menu_data["drinks"]["column_right"].map((item)=>{
                        return [item[0], "€" + item[1]]
                      }),
                        ["", ""]
                    ]}
                  />
              </GridItem>
          </GridContainer>
          <br/>
          <GridContainer justify={"center"}>
              <GridItem xs={12} md={4}>
                <h3>Tapas Koud</h3>
                  <Table
                    tableData={[
                      ...menu_data["cold_tapas"].map((item)=>{
                        return [item[0], "€" + item[1]]
                      }),
                        ["", ""]
                    ]}
                  />
                <p>
                  Sausje naar keuze inbegrepen<br/>
                  Ook vegetarische tapas verkrijgbaar
                </p>
                <p>
                  Glutenvrij mogelijk (+€5 per persoon) - op voorhand aan te vragen
                </p>
              </GridItem>
              <GridItem xs={12} md={4}>
                <h3>Tapas Warm</h3>
                  <Table
                    tableData={[
                      ...menu_data["warm_tapas"].map((item)=>{
                        return [item[0], "€" + item[1]]
                      }),
                        ["", ""]
                    ]}
                  />
              </GridItem>
          </GridContainer>
        <br/>
        <GridContainer justify={"center"}>
          <GridItem xs={12}>
            <h3>Voor de grote honger</h3>
          </GridItem>
          <GridItem xs={12} md={4}>
            <Table
              tableData={[
                ...menu_data["meals"].map((my_row)=>{
                  return [ReactHtmlParser(my_row[0].replace('\n', "<br/>")),  "€" + my_row[1]]
                }),
                ["", ""]
              ]}
            />
            <p>
              Gerechten met een * zijn op voorhand te bestellen
            </p>
            <p>
              Glutenvrij mogelijk (+€5 per persoon) - op voorhand aan te vragen
            </p>
          </GridItem>
        </GridContainer>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default Menu;